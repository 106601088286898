import React, { useEffect } from 'react';
// import './PrivacyPolicy.css'; // Old import
import './ContentPage.css'; // New import
import SEO from './SEO';

const PrivacyPolicy = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <SEO 
                title="Privacy Policy"
                description="Read the Privacy Policy for StageDive Philly."
            />
            <div className="content-page-container">
                <header className="content-page-header">
                    <h1>Privacy Policy</h1>
                </header>
                <div className="content-page-content">
                    <section className="content-page-section">
                        <h2>Introduction</h2>
                        <p>Welcome to StageDive Philly ("we," "us," "our"). We are committed to protecting your personal information and your right to privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website stagedive.io (the "Site"). Please read this privacy policy carefully. If you do not agree with the terms of this privacy policy, please do not access the site.</p>
                        <p>We reserve the right to make changes to this Privacy Policy at any time and for any reason. We will alert you about any changes by updating the "Last updated" date of this Privacy Policy. You are encouraged to periodically review this Privacy Policy to stay informed of updates.</p>
                        <p><strong>Last updated:</strong> {new Date().toLocaleDateString('en-US')}</p> {/* Automatically inserts current date */}
                    </section>

                    <section className="content-page-section">
                        <h2>Information We Collect</h2>
                        <p>We may collect information about you in a variety of ways. The information we may collect on the Site includes:</p>

                        <h3>Personal Data</h3>
                        <p>Personally identifiable information, such as your name and email address, that you voluntarily give to us when you register for an account or when you submit an event through our event submission form.</p>

                        <h3>Event Submission Data</h3>
                        <p>Information related to events you submit, including event name, date, time, venue details, descriptions, images, and links. If you submit this data while logged in, it may be associated with your account.</p>

                        <h3>Derivative Data</h3>
                        <p>Information our servers automatically collect when you access the Site, such as your IP address, your browser type, your operating system, your access times, and the pages you have viewed directly before and after accessing the Site. </p>

                        <h3>Data from Cookies and Local Storage</h3>
                        <p>We may use cookies, web beacons, tracking pixels, local storage, and other tracking technologies on the Site to help customize the Site and improve your experience. This may include:</p>
                        <ul>
                            <li>Session cookies to manage your login state.</li>
                            <li>Preference cookies to remember your settings (like theme choice or whether you've seen the welcome message).</li>
                            <li>Analytics cookies (if you implement analytics) to understand site usage.</li>
                        </ul>
                        <p>Most browsers are set to accept cookies by default. You can usually choose to set your browser to remove or reject browser cookies. Please note that if you choose to remove or reject cookies, this could affect the availability and functionality of the Site.</p>
                    </section>

                    <section className="content-page-section">
                        <h2>How We Use Your Information</h2>
                        <p>Having accurate information about you permits us to provide you with a smooth, efficient, and customized experience. Specifically, we may use information collected about you via the Site to:</p>
                        <ul>
                            <li>Create and manage your account.</li>
                            <li>Process and display event submissions provided by users.</li>
                            <li>Email you regarding your account or event submissions.</li>
                            <li>Increase the efficiency and operation of the Site.</li>
                            <li>Monitor and analyze usage and trends to improve your experience with the Site.</li>
                            <li>Perform other business activities as needed.</li>
                            <li>Prevent fraudulent transactions, monitor against theft, and protect against criminal activity.</li>
                            <li>Respond to user inquiries and offer support.</li>
                        </ul>
                    </section>

                    <section className="content-page-section">
                        <h2>Disclosure of Your Information</h2>
                        <p>We do not sell, trade, or otherwise transfer your personally identifiable information to outside parties unless we provide users with advance notice. This does not include website hosting partners and other parties who assist us in operating our website, conducting our business, or serving our users, so long as those parties agree to keep this information confidential.</p>
                        <p>We may also release information when its release is appropriate to comply with the law, enforce our site policies, or protect ours or others' rights, property, or safety.</p>
                        {/* Add specific third parties if applicable, e.g., Google Analytics, database providers */}
                    </section>

                     <section className="content-page-section">
                        <h2>Security of Your Information</h2>
                        <p>We use administrative, technical, and physical security measures to help protect your personal information. While we have taken reasonable steps to secure the personal information you provide to us, please be aware that despite our efforts, no security measures are perfect or impenetrable, and no method of data transmission can be guaranteed against any interception or other type of misuse.</p>
                    </section>

                     <section className="content-page-section">
                        <h2>Policy for Children</h2>
                        <p>We do not knowingly solicit information from or market to children under the age of 13. If you become aware of any data we have collected from children under age 13, please contact us using the contact information provided below.</p>
                    </section>

                    <section className="content-page-section">
                        <h2>Your Data Rights</h2>
                        <p>Depending on your location, you may have certain rights regarding your personal data, such as the right to access, correct, or delete your data. If you wish to exercise these rights, please contact us.</p>
                        {/* Note: You'll need mechanisms to fulfill these requests if applicable. */}
                    </section>


                    <section className="content-page-section">
                        <h2>Contact Us</h2>
                        <p>If you have questions or comments about this Privacy Policy, please contact us at: info@stagedive.io</p>
                    </section>
                </div>
            </div>
        </>
    );
};

export default PrivacyPolicy; 