import React, { useEffect } from 'react';
// import './TermsAndConditions.css'; // Old import
import './ContentPage.css'; // New import
import SEO from './SEO';

const TermsAndConditions = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <SEO 
                title="Terms & Conditions"
                description="Read the Terms and Conditions for using StageDive Philly."
            />
            <div className="content-page-container">
                <header className="content-page-header">
                    <h1>Terms & Conditions</h1>
                </header>
                <div className="content-page-content">
                    <p>By accessing www.stagedivephilly.com (“StageDive”), you agree to the following Terms and Conditions. Please read them carefully before using the site.</p>
                    
                    <section className="content-page-section">
                        <h2>1. General Disclaimer</h2>
                        <p>StageDive is provided as a public service “as is” and “as available.” While we do our best to keep everything accurate and up to date, we make no promises or guarantees—express or implied—about the reliability, accuracy, availability, or completeness of the content on this site.</p>
                        <p>We specifically disclaim any warranties of merchantability, fitness for a particular purpose, or non-infringement. We are not responsible for any loss, damage, or inconvenience that may arise from using or relying on this site or its content.</p>
                    </section>
                    
                    <section className="content-page-section">
                        <h2>2. Links to Third-Party Sites</h2>
                        <p>StageDive may include links to external websites such as venues, ticket providers, or news sources to help users find more information about events.</p>
                        <p>These links are provided for convenience only. We don't own or control these third-party sites, and we're not responsible for their content, availability, or practices. Linking to them does not mean we endorse or are affiliated with those sites.</p>
                        <p>Any issues or questions related to third-party content should be directed to the appropriate site or service.</p>
                    </section>

                    <section className="content-page-section">
                        <h2>3. Affiliate Disclosure</h2>
                        <p>StageDive may earn a small commission when users purchase tickets through affiliate links included in some event listings. This does not affect the price you pay.</p>
                        <p>Affiliate relationships do not influence which events we list or how we present them. Our goal is to help you discover shows—not sell you something you don't want.</p>
                    </section>

                    <section className="content-page-section">
                        <h2>4. Intellectual Property</h2>
                        <p>Unless otherwise noted, all text, layout, visuals, and other original content on StageDive belong to us or our content partners and are protected under copyright law.</p>
                        <p>You are welcome to use StageDive for personal, non-commercial purposes. Reproducing or distributing content from the site without our written permission is not allowed.</p>
                    </section>

                    <section className="content-page-section">
                        <h2>5. Legal Jurisdiction</h2>
                        <p>StageDive is operated from the state of Pennsylvania. Any disputes related to these Terms or your use of the site will be governed by Pennsylvania law, without regard to conflicts of law principles.</p>
                        <p>We reserve the right to update or modify these Terms at any time. Changes will take effect once posted.</p>
                    </section>
                    
                    <section className="content-page-section">
                        <h2>6. Changes to These Terms</h2>
                        <p>If we make updates to our Terms and Conditions, we'll post them right here. By continuing to use the site after changes are posted, you accept the revised Terms.</p>
                    </section>

                    <section className="content-page-section">
                        <h2>7. Contact Us</h2>
                        <p>Got questions or concerns? You can reach us at:<br />info@stagedivephilly.com</p>
                    </section>
                </div>
            </div>
        </>
    );
};

export default TermsAndConditions; 